import { savedSearchActionTypes } from '../actiontypes/savedSearch';
import {savedSearchService} from '../services';
import { toast } from 'react-toastify';

export const savedsearchActions = {
    getAll,
    add,
    update,
    remove
};

function getAll() {
    return dispatch => {
        dispatch(request());

        savedSearchService.getAll()
            .then(
                res => {
                  dispatch(success(res));
                },
                error => {
                  dispatch(failure(error));
                }
            );
    };

    function request() { return { type: savedSearchActionTypes.GET_SAVED_SEARCHES_REQUEST } }
    function success(items) { return { type: savedSearchActionTypes.GET_SAVED_SEARCHES_SUCCESS, items } }
    function failure(error) { return { type: savedSearchActionTypes.GET_SAVED_SEARCHES_FAILURE, error } }
}

function add(data) {
  return dispatch => {
      dispatch(request());
      return new Promise((resolve, reject) => {
        savedSearchService.add(data)
          .then(
              res => {
                dispatch(success(res));
                resolve(res);
                toast.success('Uw zoekprofiel is opgeslagen')
              },
              error => {
                dispatch(failure(error));
                reject(error);

              }
          );
      });
     
  };

  function request() { return { type: savedSearchActionTypes.ADD_SAVED_SEARCH_REQUEST } }
  function success(item) { return { type: savedSearchActionTypes.ADD_SAVED_SEARCH_SUCCESS, item } }
  function failure(error) { return { type: savedSearchActionTypes.ADD_SAVED_SEARCH_FAILURE, error } }
}

function update(id, data) {
  return dispatch => {
      dispatch(request());
      return new Promise((resolve, reject) => {
        savedSearchService.update(id, data)
            .then(
                res => {
                  dispatch(success(res));
                  resolve(res);
                  dispatch(getAll());
                  toast.success(`${res.filter_name} is gewijzigd`)
                },
                error => {
                  dispatch(failure(error));
                  reject();
                }
            );
        });
  };

  function request() { return { type: savedSearchActionTypes.UPDATE_SAVED_SEARCH_REQUEST } }
  function success(item) { return { type: savedSearchActionTypes.UPDATE_SAVED_SEARCH_SUCCESS, item } }
  function failure(error) { return { type: savedSearchActionTypes.UPDATE_SAVED_SEARCH_FAILURE, error } }
}

function remove(id) {
  return dispatch => {
      dispatch(request());
      return new Promise((resolve, reject) => {
        savedSearchService.remove(id)
            .then(
                res => {
                  dispatch(success(id))
                  resolve(res);
                  toast.success('Het zoekprofiel is verwijderd');
                },
                error => {
                  dispatch(failure(error));
                  reject(error)
                }
            );
        });
  };

  function request() { return { type: savedSearchActionTypes.REMOVE_SAVED_SEARCH_REQUEST } }
  function success(id) { return { type: savedSearchActionTypes.REMOVE_SAVED_SEARCH_SUCCESS, id } }
  function failure(error) { return { type: savedSearchActionTypes.REMOVE_SAVED_SEARCH_FAILURE, error } }
}