import React from 'react';

import {withRouter} from 'react-router-dom';

import * as S from './Share.module.scss';
import fbIcon from '../../assets/images/fb.svg';
import twIcon from '../../assets/images/tw.svg';
import liIcon from '../../assets/images/li.svg';
import waIcon from '../../assets/images/wa.svg';
import mailIcon from '../../assets/images/mail.svg';


const Share = (props) => {
  let title = props.title;
  let url = encodeURIComponent(window.location.href);
  let isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);

  const handleUrl = (platform) => {
    let res;
    switch(platform) {
      case 'facebook':
        res = `https://www.facebook.com/sharer.php?u=${url}`;
      break;
      case 'twitter':
        res = `https://twitter.com/share?url=${url}&text=${title}`;
      break;
      case 'whatsapp':
        res = isMobile ? `whatsapp://send?text=${url}` : `https://wa.me/?text=${title}, ${url}`;
      break;
      case 'linkedin':
        res = `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}`
      break;
      case 'mail':
        res = `mailto:?subject=${title}&body=${url}`;
      break;
      default:
        return res;
    }
    return res;
  }

  return (
    <div className={S.root}>
      <a
        target="_blank"
        title="Deel via Facebook"
        rel="noopener noreferrer"
        href={handleUrl('facebook')}>
        <img src={fbIcon} alt="Deel via Facebook"/>
      </a>
      <a
        target="_blank"
        title="Deel via Twitter"
        rel="noopener noreferrer"
        href={handleUrl('twitter')}>
        <img src={twIcon} alt="Deel via Twitter"/>
      </a>
      <a
        target="_blank"
        title="Deel via LinkedIn"
        rel="noopener noreferrer"
        href={handleUrl('linkedin')}>
        <img src={liIcon} alt="Deel via LinkedIn"/>
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        title="Deel via Whatsapp"
        data-action="share/whatsapp/share"
        href={handleUrl('whatsapp')}>
        <img src={waIcon} alt="Deel via Whatsapp"/>
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        title="Deel via Mail"
        href={handleUrl('mail')}>
        <img src={mailIcon} alt="Deel via Mail"/>
      </a>
    </div>
  )
}

export default withRouter(Share);
