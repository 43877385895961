

const SiteSettings = {
    NODE_ENV: process.env.NODE_ENV,

    REACT_APP_API_URL: process.env.REACT_APP_API_URL,
    REACT_APP_ES_URL: process.env.REACT_APP_ES_URL,
    REACT_APP_TRACKING_CODE: process.env.REACT_APP_TRACKING_CODE,

    REACT_APP_THEME: process.env.REACT_APP_THEME,
    REACT_APP_SEARCH_PARAMS: process.env.REACT_APP_SEARCH_PARAMS,
    REACT_APP_TITLE: process.env.REACT_APP_TITLE,
    REACT_APP_MAIN_LINK: process.env.REACT_APP_MAIN_LINK,
}

export default SiteSettings;
