import { userActionTypes } from '../actiontypes/users';
import {authService} from '../services';
import {savedsearchActions} from './savedsearches.actions';
import {savedVacanciesActions} from './savedVacancies.actions';
import history from '../shared/history';
import { toast } from 'react-toastify';

export const userActions = {
    login,
    loginMFA,
    logout,
    loggedIn,
    checkUser,
    register,
    activate,
    getAll,
    setPassword,
    addFavorite,
    requestPasswordLink,
    removeFavorite,
    enableMFA,
    disableMFA,
    passwordResetConfirm,
    refreshToken,
    removeAccount,
    pageSize
};

function login(username, password, next='/') {
    return dispatch => {
        dispatch(request({ username }));

        authService.login(username, password)
            .then(
                token => {
                    if(token.ephemeral_token){
                        dispatch(requireMFA(token));
                        history.push('/login-mfa?next='+next);

                    } else {
                        authService.getMe()
                            .then(res => {
                                dispatch(success(res));
                                dispatch(savedsearchActions.getAll());
                                history.push(next);
                            });
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(user) { return { type: userActionTypes.LOGIN_REQUEST, user } }
    function requireMFA(user) { return { type: userActionTypes.LOGIN_REQUIRE_MFA, user} }
    function success(user) { return { type: userActionTypes.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userActionTypes.LOGIN_FAILURE, error } }
}

function loginMFA(token, next="/") {
    return dispatch => {
        dispatch(request({token}));
        authService.loginMFA(token)
            .then(
                user => {
                    dispatch(success(user));
                    history.push(next);
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }

    function request(user) { return { type: userActionTypes.LOGIN_MFA_REQUEST, user } }
    function success(user) { return { type: userActionTypes.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userActionTypes.LOGIN_FAILURE, error } }
}

function enableMFA(code) {
    return dispatch => {
        dispatch(request(code));
        authService.enableMFA(code)
            .then(
              code => {
                  authService.getMe()
                  .then(res => {
                      toast.success("Twee-factor-authenticatie ingeschakeld");
                      dispatch(success(res));
                      history.push('/account');
                  })
              },
              error => {
                  dispatch(failure(error));
              }
            );
    };

    function request(code) { return { type: userActionTypes.ENABLE_MFA_REQUEST, code } }
    function success(user) { return { type: userActionTypes.ENABLE_MFA_SUCCESS, user } }
    function failure(error) { return { type: userActionTypes.ENABLE_MFA_FAILURE, error } }
}

function disableMFA() {
    return dispatch => {
        dispatch(request());
        authService.disableMFA()
            .then(
                result => {
                    authService.getMe()
                            .then(res => {
                                toast.success("Twee-factor-authenticatie uitgeschakeld");
                                dispatch(success(res));
                                history.push('/account');
                            })

                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(code) { return { type: userActionTypes.DISABLE_MFA_REQUEST, code } }
    function success(user) { return { type: userActionTypes.DISABLE_MFA_SUCCESS, user } }
    function failure(error) { return { type: userActionTypes.DISABLE_MFA_FAILURE, error } }
}

function loggedIn() {
    return dispatch => {
        dispatch(request());
        dispatch(success(authService.loggedIn()));
    };
    function request() { return { type: userActionTypes.TOKEN_REQUEST } }
    function success(status) { return { type: userActionTypes.TOKEN_SUCCESS, status } }
}

function checkUser() {
    return dispatch => {

        dispatch(request());
        let token = sessionStorage.getItem('access');
        let user = sessionStorage.getItem('profile');
        if(user !== 'undefined' && token) {
            let parsed = JSON.parse(user);
            dispatch(success(parsed));
            dispatch(savedsearchActions.getAll());

        } else {
            sessionStorage.clear();
            dispatch(savedVacanciesActions.getAll())
            dispatch(failure());
        }
      };

      function request(user) { return { type: userActionTypes.LOGIN_REQUEST, user } }
      function success(user) { return { type: userActionTypes.LOGIN_SUCCESS, user } }
      function failure(error) { return { type: userActionTypes.LOGIN_FAILURE, error } }
}


function logout() {
    authService.logout().then(history.push('/'));
    savedVacanciesActions.getAll()
    return {type: 'LOGOUT'}

}

function addFavorite(id) {
    return dispatch => {
        dispatch(request());
        let user = JSON.parse(sessionStorage.getItem('profile'));

        if(user) {
            authService.addFavorite(id)
                .then(
                    res => {
                        authService.getMe()
                            .then(res => {
                                dispatch(success(res));
                                return Promise.resolve(res)
                            })

                    },
                    error => {
                        dispatch(failure(error));
                    }
                );
        }

    };

    function request(user) { return { type: userActionTypes.ADD_FAVORITE_REQUEST } }
    function success(user) { return { type: userActionTypes.ADD_FAVORITE_SUCCESS, user } }
    function failure(error) { return { type: userActionTypes.ADD_FAVORITE_FAILURE, error } }
}

function removeFavorite(id) {
    return dispatch => {
        dispatch(request());
        return new Promise((resolve, reject) => {
            let user = JSON.parse(sessionStorage.getItem('profile'));

            if(user) {
                authService.removeFavorite(id)
                    .then(
                        res => {
                            authService.getMe()
                                .then(res => {
                                    dispatch(success(res));
                                    resolve(res);
                                });
                        },
                        error => {
                            dispatch(failure(error));
                            reject();
                        }
                    );
            }
            });


    };

    function request(user) { return { type: userActionTypes.REMOVE_FAVORITE_REQUEST } }
    function success(user) { return { type: userActionTypes.REMOVE_FAVORITE_SUCCESS, user } }
    function failure(error) { return { type: userActionTypes.REMOVE_FAVORITE_FAILURE, error } }
}


function register(user) {
    return dispatch => {
        dispatch(request());

        authService.register(user)
            .then(
                res => {
                    dispatch(success(res));
                    toast.success('U bent aangemeld, bekijk uw mail en activeer uw account');
                    return Promise.resolve();
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: userActionTypes.REGISTER_REQUEST } }
    function success(user) { return { type: userActionTypes.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userActionTypes.REGISTER_FAILURE, error } }
}

function activate(form) {
    return dispatch => {
        dispatch(request());

        authService.activate(form)
            .then(
                res => {
                    dispatch(success());
                    toast.success('Uw account is geactiveerd, u kunt nu inloggen');
                    history.push('/login');
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: userActionTypes.ACTIVATE_REQUEST } }
    function success() { return { type: userActionTypes.ACTIVATE_SUCCESS } }
    function failure(error) { return { type: userActionTypes.ACTIVATE_FAILURE, error } }
}

function setPassword(form) {
    return dispatch => {
        dispatch(request());

        authService.setPassword(form)
            .then(
                res => {
                    dispatch(success());
                    toast.success("Uw wachtwoord is gewijzigd");
                    history.push('/account');
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: userActionTypes.PASSWORD_RESET_REQUEST } }
    function success() { return { type: userActionTypes.PASSWORD_RESET_SUCCESS } }
    function failure(error) { return { type: userActionTypes.PASSWORD_RESET_FAILURE, error } }
}

function requestPasswordLink(email) {
    return dispatch => {
        dispatch(request());

        authService.passwordReset(email)
            .then(
                res => {
                    dispatch(success());
                    toast.success("Er is een email met reset link verstuurd");
                    return Promise.resolve();
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: userActionTypes.PASSWORD_FORGOT_REQUEST } }
    function success() { return { type: userActionTypes.PASSWORD_FORGOT_SUCCESS } }
    function failure(error) { return { type: userActionTypes.PASSWORD_FORGOT_FAILURE, error } }
}

function passwordResetConfirm(form) {
    return dispatch => {
        dispatch(request());

        authService.setPasswordConfirm(form)
            .then(
                res => {
                    dispatch(success());
                    toast.success("Uw wachtwoord is succesvol aangepast. Log weer opnieuw in.");
                    history.push('/login')
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: userActionTypes.SET_PASSWORD_CONFIRM_REQUEST } }
    function success() { return { type: userActionTypes.SET_PASSWORD_CONFIRM_SUCCESS } }
    function failure(error) { return { type: userActionTypes.SET_PASSWORD_CONFIRM_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request());

        authService.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userActionTypes.GETALL_REQUEST } }
    function success(users) { return { type: userActionTypes.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userActionTypes.GETALL_FAILURE, error } }
}

function removeAccount(password) {
    return dispatch => {
        dispatch(request());

        authService.removeAccount(password)
            .then(
                res => {
                    dispatch(success());
                    toast.success('Account succesvol verwijderd.')
                    history.push('/logout');
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(id) { return { type: userActionTypes.DELETE_REQUEST, id } }
    function success(id) { return { type: userActionTypes.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: userActionTypes.DELETE_FAILURE, id, error } }
}


export function refreshToken(dispatch) {
    var freshTokenPromise = authService.refreshAccessToken()
        .then(t => {
            dispatch({
                type: userActionTypes.DONE_REFRESHING_TOKEN
            });
            return t.access ? Promise.resolve() : Promise.reject({
                message: 'could not refresh token'
            });
        })
        .catch(e => {
            dispatch({
                type: userActionTypes.DONE_REFRESHING_TOKEN
            });
            return Promise.reject(e);
        });

    dispatch({
        type: userActionTypes.REFRESHING_TOKEN,
        freshTokenPromise
    });

    return freshTokenPromise;
}

export function pageSize(size) {
    return dispatch => {
        dispatch({
            type: userActionTypes.VACANCY_PAGE_SIZE,
            size
        });

    };
}
