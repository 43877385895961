import React, { Fragment } from 'react';
import {connect} from 'react-redux';

import {
    Router,
    Route,
    Switch
  } from 'react-router-dom';

import {
  Home,
  LoginRoot,
  LoginMFARoot,
  AccountRoot,
  CreateRoot,
  ActivateRoot,
  ForgotRoot,
  VacancyRoot,
  PasswordResetRoot,
  LogoutRoot,
  MFARoot,
  RemoveRoot,
  SetPasswordRoot,
  HelpRoot
} from './routes';

import history from './shared/history';

import PrivateRoute from './components/PrivateRoute';

import {userActions, vacancyActions} from './actions';
import { ToastContainer } from 'react-toastify';

import {ScrollProvider} from './context/scroll.context';

import 'react-toastify/dist/ReactToastify.min.css';

import './assets/styles/styles.scss';

const App = (componentProps) => {
  React.useEffect(() => {
    componentProps.dispatch(userActions.checkUser());
    componentProps.dispatch(vacancyActions.getOrganisations())
    componentProps.dispatch(vacancyActions.getNonCountries())
    componentProps.dispatch(vacancyActions.getDisciplines())
  }, [])

  return (
    <Fragment>
      <ScrollProvider>
        <ToastContainer
          hideProgressBar={true}
          autoClose={5000}
          position="top-right"
        />
        <Router history={history}>
          <Switch>
              <Route exact path="/login" component={LoginRoot} />
              <Route exact path="/login-mfa" component={LoginMFARoot} />

              <PrivateRoute exact path="/logout" component={LogoutRoot}  authed={componentProps.authentication}/>
              <Route exact path="/create" component={CreateRoot} />
              <Route exact path="/forgot" component={ForgotRoot} />
              <Route exact path="/activate/:uid/:token" component={ActivateRoot} />

              <PrivateRoute exact path="/setupmfa" component={MFARoot} authed={componentProps.authentication}/>/>
              <Route exact path="/set-password/:uid/:token" component={SetPasswordRoot} />

              <Route path="/vacancy/:id" exact  render={(props) => <VacancyRoot {...props} authed={componentProps.authentication} />}/>

              <PrivateRoute path="/account" exact component={AccountRoot} authed={componentProps.authentication} />
              <PrivateRoute path="/password-reset" exact component={PasswordResetRoot} authed={componentProps.authentication} />
              <PrivateRoute path="/remove" exact component={RemoveRoot} authed={componentProps.authentication} />
              <Route path="/help" exact component={HelpRoot} authed={componentProps.authentication} />

              <Route render={(props) => <Home {...props} authed={componentProps.authentication} />}/>
          </Switch>
        </Router>
      </ScrollProvider>
    </Fragment>
  )
}
const mapStateToProps = state => {
    console.log(state)
    return state
};

export default connect(mapStateToProps)(App);
